let featuresOptionMixin = {
    data() {
      return {
        featuresOption: [
            {
              name: "仪器容易上手",
              key: 1,
            },
            {
              name: "仪器较新",
              key: 2,
            },
            {
              name: "仪器较小容易拿",
              key: 3,
            },
            {
              name: "价格划算",
              key: 4,
            },
            {
              name: "易归还",
              key: 5,
            },
            {
              name: "使用时间长",
              key: 6,
            },
          ]
      }
    },
    methods: {
     
    }
  }
  
  export default featuresOptionMixin