<template>
  <div class="giveBackDetails">
    <div class="main">
      <overflowYHidden>
        <div class="cost">
          <div class="spaceBetween">
            <span class="price">{{ orderData.estimatedAmount || '-' }}元</span>
            <!-- <span class="toDetails" @click="toCostDetails">费用明细<van-icon name="arrow" /></span> -->
          </div>
          <div class="spaceBetween">
            {{ formatDateTime(orderData.orderTime) }}
          </div>
          <div class="spaceBetween">
            <span>订单时长</span>
            <span>{{ $formatDurationDDMMHH(orderData.useTime / 1000) }}</span>
          </div>
          <!-- <div class="spaceBetween">
            <span>归还科室</span>
            <span>{{ typeof orderData.tenantName != 'undefined' && orderData.tenantName != null ? orderData.tenantName : '' }}</span>
          </div> -->
        </div>
        <div class="evaluate">
          <div class="title">您对此次使用{{ typeof orderData.name != 'undefined' && orderData.name != null ? orderData.name : '' }}满意吗？</div>
          <div class="rate">
            <van-rate
                v-model="baseData.rate"
                :gutter="30"
                :size="28"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
            />
          </div>
        </div>
        <div class="evaluate">
          <div class="title">您最满意的是什么？（可多选）</div>
          <div class="choose">
            <span
                class="item"
                :class="{ itemActive: featuresList.indexOf(item.key) > -1 }"
                v-for="item in featuresOption"
                :key="item.key"
                @click="checkFeatures(item.key)"
            >
              {{ item.name }}
            </span>
          </div>
          <div class="textarea">
            <el-input
                type="textarea"
                maxlength="50"
                show-word-limit
                :autosize="{ minRows: 5, maxRows: 6 }"
                placeholder="请写出你的使用心得"
                v-model="baseData.comment"
            >
            </el-input>
          </div>
        </div>
      </overflowYHidden>
    </div>
    <div class="footer" @click="shareRevert">确定</div>
  </div>
</template>

<script>
import overflowYHidden from '@/components/overflowYHidden'
import featuresOption from '@/mixins/featuresOption/index.js'

export default {
  components: { overflowYHidden },
  mixins: [featuresOption],
  data() {
    return {
      baseData: {
        rate: 5,
        comment: '',
        id: ''
      },
      orderData: {
        amount: '',
        time: ''
      },
      featuresList: []
    }
  },
  created() {
    this.baseData.id = this.$route.query.id
    this.orderDetail()
  },
  methods: {
    orderDetail() {
      this.$api.deviceBorrowModule
          .orderDetail({
            id: this.baseData.id
          })
          .then((res) => {
            this.orderData = res.data
            console.log(this.orderData)
          })
          .catch((e) => {
          })
    },
    checkFeatures(key) {
      let _idx = this.featuresList.indexOf(key)
      if (_idx == -1) {
        this.featuresList.push(key)
      } else {
        this.featuresList.splice(_idx, 1)
      }
    },
    shareRevert() {
      this.$dialog.confirm({
        message: `确定归还设备？`,
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            this.$api.deviceBorrowModule
                .shareRevert({
                  id: this.baseData.id,
                  comment: this.baseData.comment,
                  features: this.featuresList.join(),
                  rate: this.baseData.rate
                })
                .then((res) => {
                  this.$toast.success('操作成功')
                  setTimeout(() => {
                    localStorage.setItem('allDeviceUpdate', new Date().getTime())
                    localStorage.setItem('updateDeviceBorrow', new Date().getTime())
                    localStorage.setItem('goBack', new Date().getTime())
                    done()
                    this.$back()
                  }, 500)
                })
                .catch((e) => {
                  done()
                  this.$toast.fail(e.data.msg)
                })
          } else {
            done()
          }
        }
      })
    },
    toCostDetails() {
      this.$push('deviceBorrow/costDetails', {})
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.giveBackDetails {
  height: 100%;
  padding: 10px;

  .main {
    height: calc(100% - 60px);
    overflow: hidden;
  }

  .header {
    background: #ffffff;
    padding: 0 12px;
    border-radius: 2px;
    font-size: 12px;
    color: $color3;
    height: 76px;
    margin-bottom: 10px;

    .spaceBetween {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      padding-top: 6px;

      .left {
        color: $color1;
      }

      .right {
        display: inline-block;
        width: 56px;
        height: 24px;
        line-height: 24px;
        background: #00e78c;
        border-radius: 4px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .cost {
    background: #ffffff;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    color: $color3;
    margin-bottom: 10px;

    .spaceBetween {
      display: flex;
      justify-content: space-between;
      line-height: 24px;

      .price {
        font-size: 18px;
        font-weight: bold;
        color: $color1;
      }

      .toDetails {
        padding-top: 8px;
        color: $color2;
      }
    }
  }

  .evaluate {
    background: #ffffff;
    border-radius: 12px;
    padding: 10px 12px;
    margin-bottom: 12px;

    .title {
      font-size: 14px;
      color: $color1;
      font-weight: 500;
    }

    .rate {
      text-align: center;
      margin: 40px 0;
    }

    .choose {
      padding: 12px 0;

      .item {
        color: $color2;
        display: inline-block;
        line-height: 24px;
        font-size: 12px;
        border: 1px solid #d9d9d9;
        padding: 0 8px;
        border-radius: 13px;
        margin-right: 12px;
        margin-bottom: 8px;
      }

      .itemActive {
        border: 1px solid #3e73fb;
        color: #3e73fb;
      }
    }

    .textarea {
      padding-top: 12px;
    }
  }

  .footer {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3e73fb;
    border-radius: 18px;
    color: #ffffff;
    font-size: 16px;
  }
}

::v-deep .el-textarea__inner {
  border: 0 none;
  background: #fbfbfb;
}
</style>